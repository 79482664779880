import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SocialMediaFeedComponent } from './social-media-feed/social-media-feed.component';
import { FeatureContentComponent } from './feature-content/feature-content.component';
import { SlickSliderComponent } from './slick-slider.component';
import { LightGalleryComponent } from './light-gallery.component';
import { HomePageComponent } from './home-page/home-page.component';
import { ShopPageComponent } from './shop-page/shop-page.component';

@NgModule({
  declarations: [
    AppComponent,
    SocialMediaFeedComponent,
    FeatureContentComponent,
    SlickSliderComponent,
    LightGalleryComponent,
    HomePageComponent,
    ShopPageComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
