import { Component } from '@angular/core';
// import { Observable, of } from 'rxjs';
import { SocialMediaApisService } from './social-media-apis.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private socialApi: SocialMediaApisService) { }
  title = 'Eclectic Mob';
  contentFeed = [];
  // I'll push everything into the content feed and sort it with a  filter;
  // I'll put a cap on what's shown and have a scrollable refresh if there's more content
  // Need to add contact information
  // Maybe I should add an meme's Submissions form

  // Twitch --> Youtube --> Mixer
  twitchAccounts = [
    { name : 'Eclectic Mob', link : 'https://www.twitch.tv/eclecticmob' },
    { name : 'Negative Hiei', link : 'https://www.twitch.tv/em_negativehiei'},
    { name : 'Salt Vader', link : 'https://www.twitch.tv/em_saltvader'},
  ];

  email = 'eclecticMob@gmail.com';

  youtubeAccount = 'https://www.youtube.com/channel/UCBuiXD93BtBcflhjOYxUAZQ';
  // Instagram --> Facebook
  instagramAccount = 'https://www.instagram.com/eclectic_mob';

  // Donations
  venmoAccount = 'https://www.venmo.com/Eclectic-Mob';
  cashAppAccount = 'https://cash.app/$Eclecticmob';

  // Twitter --> Future Update
  // Spooner --> Future Update
  // Shopify --> Future update

  // I"LL FINISH UP ALL THE DESIGN AND PHP CONFIGURATION FOR A FORUM
  // I'll the a main index with feed content and the wordpress blog powered on a different section
  // I'll have ads run on the site but in covert places if I can help it
  // Angular app will also control the shop
  // I can use Jenkins to do the deployment builds for esch sections of the application
  // I'll add api hooks to the wordpress to update content on the angular like featured content that we want to promote

  // ngOnInit() {
  //   // this.socialApi.getYoutubeFeed()
  //   console.log(this.socialApi.getTwitchLiveFeed());
  // }

  refreshFeed() {
    this.contentFeed = [];
  }

  showTwitchClips() {
  }

}
