import { Component, OnInit, AfterContentInit } from '@angular/core';
declare var jQuery: any;

@Component({
  selector: 'app-feature-content',
  templateUrl: './feature-content.component.html',
  styleUrls: ['./feature-content.component.scss']
})
export class FeatureContentComponent implements OnInit, AfterContentInit {

  constructor() { }
  featuredSlides = [{ type : 'eclecticMobRoster' }];

  ngOnInit() {
  }

  ngAfterContentInit() {
    jQuery( document ).ready(() => {
      const body = jQuery('body');
      setTimeout(() => {
        const findCenter = () => {
          const featureContent = jQuery('.feature-content');
          const height = parseFloat(featureContent.css( 'padding-top' ));
          const top = featureContent.position().top;
          const center = top + height / 2;

          return center;
        };

        body.prepend(
          `<div id="Shine-Container">
            <img id="Shine" class="rotating" style="top: ${ findCenter() }px" src="../assets/Background-shine.png" alt="shine">
          </div>`
        );

        setTimeout(() => {
          jQuery('#Shine').addClass('activate');
          jQuery(window).resize(() => {
            jQuery( '#Shine' ).css('top', `${ findCenter() }px`);
          });
        }, 100);
      }, 500);
    });
  }

}
