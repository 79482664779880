import { Component, Input, ElementRef, AfterViewInit, AfterContentInit } from '@angular/core';
declare var jQuery: any;

@Component({
    selector: 'app-light-gallery',
    template: `
        <ng-content></ng-content>
    `
})
export class LightGalleryComponent implements AfterContentInit {
    @Input() options: any;

    $element: any;

    defaultOptions: any = {};

    constructor(private el: ElementRef) {
    }

    ngAfterContentInit() {
        // for (var key in this.options) {
        //     this.defaultOptions[key] = this.options[key];
        // }

        // this.$element = jQuery(this.el.nativeElement).slick(this.defaultOptions);
    }
}
