import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaApisService {
  constructor(private http: HttpClient) { }

  getInstagramFeed() {
  }

  getYoutubeFeed() {
  }

  getTwitchLiveFeed() {
  }

  getTwitchVideoClips() {
  }

}
