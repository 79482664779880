import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-media-feed',
  templateUrl: './social-media-feed.html',
  styleUrls: ['./social-media-feed.scss']
})
export class SocialMediaFeedComponent implements OnInit {
  constructor() {

  }

  // 12 results on start
  socialMediaFeed = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 1, 2];

  ngOnInit() {
  }

}
